import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <header className="websiteNavigation">
      <nav className="navbar navbar-expand navbar-dark">
        <div className="navSectionTitle">
          <NavLink className="websiteTitle" to="/contact">
            HARSH SHARMA
          </NavLink>
        </div>

        <div className="navSectionElements">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link pageTitle" to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>

          <div className="hamburger-menu">
            <input id="menu__toggle" type="checkbox" />
            <label className="menu__btn" htmlFor="menu__toggle">
              <span></span>
            </label>

            <ul className="menu__box">
              <li className="nav-item">
                <NavLink
                  className="nav-link pageTitle"
                  to="/contact"
                  onClick="menu__toggle"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navigation;
