import React from "react";

function Contact() {
  return (
    <div className="body">
      <div className="pageElements">
        <p className="sectionParagraph">
          Curiosity drives everything I do. I’m happiest uncovering simple, creative ways to solve tricky problems. Right now, I do that at DoorDash, where I'm making next-day delivery a reality for any e-commerce order.
        </p>
        <p className="sectionParagraph">
          Based in New York.{" "}
          <a
            href="https://support.keennewyork.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Associate Board at KEEN
          </a>
          . Previously Product at{" "}
          <a
            href="https://apps.apple.com/us/app/apollo-id/id1552644962"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apollo ID
          </a>{" "}
          and Investment Banking at JPM. CS + Econ at Duke. Working on my Spanish.
        </p>
        <p className="sectionParagraph">
          I’ll try almost anything once, most things twice. Always happy to connect:{" "}
          <a
            href="mailto:harsh@harshsharma.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            harsh@harshsharma.net
          </a>{" "}
          or{" "}
          <a
            href="https://www.linkedin.com/in/harshsharma2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>.
        </p>
      </div>
    </div>
  );
}

export default Contact;
